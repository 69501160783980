<template>
  <div>
    <b-card>
      <project-name />
      <project-status />
      <notes />
      <mail-cc />
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import ProjectName from '@/views/Admin/Projects/elements/ProjectName.vue'
import Notes from '@/views/Admin/Projects/elements/Notes.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import ProjectStatus from '@/views/Admin/Projects/elements/ProjectStatus.vue'
import MailCc from '@/views/Admin/Projects/elements/MailCc.vue'

export default {
  name: 'ProjectForm',
  components: {
    BCard,
    ProjectName,
    Notes,
    ProjectStatus,
    MailCc,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
