<template>
  <div>
    <b-form-group
      label="Bilgi E-Postası"
      label-for="mail_cc"
    >
      <b-form-tags
        v-model="dataItem.mail_cc"
        input-id="tags-basic"
        class="mb-2"
        add-button-text="Ekle"
        add-button-variant="danger"
        duplicate-tag-text="E-Posta daha önce eklenmiş"
        placeholder="E-Posta Adresi.."
      />
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body">
          <FeatherIcon icon="InfoIcon" />
          Eklenen e-posta adresline bilgilendirme e-postaları gönderilecektir.
        </div>
      </b-alert>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormTags, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'MailCc',
  components: {
    BFormGroup,
    BFormTags,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
}
</script>
