<template>
  <b-form-group
    label="Proje Durumu"
    label-for="status"
  >
    <validation-provider
      #default="{ errors }"
      name="Proje Durumu"
      rules="required"
    >
      <v-select
        id="status"
        v-model="dataItem.status"
        :options="statuses"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ProjectStatus',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
    statuses() {
      return [{ id: '0', title: 'Devam Ediyor' }, { id: '1', title: 'Tamamlandı' }]
    },
  },
  created() {
    localize('tr')
  },
}
</script>
