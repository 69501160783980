<template>
  <b-form-group
    label="Proje Açıklaması"
    label-for="notes"
  >
    <b-form-textarea
      id="notes"
      v-model="dataItem.notes"
      placeholder="Proje Açıklaması"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'Notes',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
}
</script>
